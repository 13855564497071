<template>
  <div id="app">
    <!-- <router-view /> -->
    <transition name="page-fade">
      <router-view></router-view>
    </transition>
    <div class="rightOption">
      <div class="rightOption_item" @click="top">
        <img src="@/assets/image/utils/one.png" alt="">
      </div>
      <div class="rightOption_item" @click="dialogVisible=true">
        <img src="@/assets/image/utils/tow.png" alt="">
      </div>
      <div class="rightOption_item" @click="dialogVisible=true">
        <img src="@/assets/image/utils/three.png" alt="">
      </div>
      <div class="rightOption_item" @click="dialogVisible=true">
        <img src="@/assets/image/utils/four.png" alt="">
      </div>
    </div>
    <!-- 选择城市 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="40%" :modal-append-to-body="false">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <!-- <i class="el-icon-refresh"></i> -->
          客服
        </div>
      </div>
      <!-- <SelectCity @selectCityback="selectCityback"></SelectCity> -->

    </el-dialog>
  </div>
</template>
<script>
import { city_list } from "@/utils/network";
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {
    this.getCityData();
  },
  methods: {
    async getCityData() {
      const res = await city_list();
      let cityList = [];
      let letterlist = [...Array(26)].map((v, i) =>
        String.fromCharCode(i + 65)
      );

      res.data.res.forEach((item, i) => {
        letterlist.forEach((value, index) => {
          if (value == item.pinyin) {
            cityList[index]
              ? cityList[index].push(item)
              : (cityList[index] = []);
          }
        });
      });
      this.$store.commit("SetisCityList", cityList);
      this.$store.commit("SetHotCityList", res.data.hot);
    },
    top() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
  },
};
</script>


<style lang="scss" scoped>
// .page-fade-enter-active,
// .page-fade-leave-active {
//   transition: opacity 0.2s ease-out;
// }

// .page-fade-enter,
// .page-fade-leave-to {
//   opacity: 0;
// }
.rightOption {
  cursor: pointer;
  top: 48%;
  right: 0;
  position: fixed;
  z-index: 99;
  width: 43px;
  height: 170px;
  background: #ffffff;
  border-radius: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .rightOption_item {
    img {
      width: 20px;
      height: 20px;
    }
  }
}
::v-deep .el-dialog {
  overflow: hidden;
  border-radius: 14px;
}
::v-deep .el-dialog__header {
  padding: 0;
}
// 登录框
.loginTitle {
  position: relative;
  height: 50px;
  background: linear-gradient(180deg, #fdf3f4, #fdf3f4 50%, #f2f4f7);
  .loginTitle_toggle {
    margin-left: 15px;

    color: #333333;

    font-weight: bold;
    font-size: 14px;
    color: #f43a47;
  }
}
</style>
