<template>
  <div class="pointer">

    <div class="header " :style="{'background':bgColor,'color':fontColor}">
      <div class="left flex_aling_center">
        <img src="@/assets/logo.png" alt="" @click="goPage('/')">

        <div class="tabs">
          <div class="tabs_item  tabs_city flex_aling_center " @click="dialogVisible=true">
            <div class="flex_aling_center tabs_active">
              <i class="el-icon-location"></i>
              <div class="city">{{currentCity?currentCity.city_name:'选择城市'}}</div>
            </div>
            <div class="city_toggle">
              [切换]
            </div>
          </div>
          <div class="tabs_item" @click="goPage('/')" :class="{'tabs_active':path=='/'}">
            首页
          </div>
          <div v-if="authType==1" class="tabs_item" @click="jump('/crewList')" :class="{'tabs_active':path=='/crewList'}">
            剧组通告
          </div>
          <div v-if="authType==1" class="tabs_item" @click="jump('/position')" :class="{'tabs_active':path=='/position'}">
            角色
          </div>

          <div class="tabs_item" @click="jump('/chat')" :class="{'tabs_active':path=='/chat'}" v-if="userInfo">
            消息
          </div>
        </div>

      </div>
      <div class="tabs">
        <!-- 暂时隐藏 -->
        <!-- <div :class="{'tabs_active':path=='/policy'}" class="tabs_item flex_aling_justify_center" @click="jump('/policy')">我要找工作</div>
        <div :class="{'tabs_active':path=='/bank'}" class="tabs_item flex_aling_justify_center" @click="jump('/bank')">我要招聘</div> -->
        <!-- -->
        <div v-if="isLogin" class="tabs_item flex_aling_justify_center" :class="{'tabs_active':path=='/mine'}" @click="goPage('/mine')">个人中心</div>
        <!-- :style="{'border-color':fontColor}" -->
        <div v-else class="tabs_item loginBtn flex_aling_justify_center" @click="login()">登录/注册</div>
        <div v-if="isLogin" class="tabs_item loginBtn flex_aling_justify_center" @click="out()">退出登录</div>
        <!-- 切换身份 测试使用 -->
        <!-- <el-dropdown @command="handleCommand">
          <div v-if="isLogin" class="tabs_item flex_aling_justify_center">
            {{authType==2?'公司':'牛马'}}
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">牛马</el-dropdown-item>
            <el-dropdown-item command="2">公司</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </div>
    <!-- 选择城市 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="70%" :modal-append-to-body="false">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-refresh"></i>
          切换城市
        </div>
      </div>
      <SelectCity @selectCityback="selectCityback"></SelectCity>

    </el-dialog>
    <!-- 登录注册 -->
    <el-dialog title="" :visible.sync="dialogVisibleLogin" width="30%" :modal-append-to-body="false">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-refresh"></i>
          {{isMember?'我要找工作':'我要招聘'}}
        </div>
      </div>
      <div class="select flex_aling_center">
        <div class="select_item  " :class="{'select_active':currentIndex==1}" @click="toggle(1)">
          短信登录
          <div class="select_line" v-if="currentIndex==1"></div>
        </div>
        <div class="select_item" :class="{'select_active':currentIndex==2}" @click="toggle(2)">
          密码登录
          <div class="select_line" v-if="currentIndex==2"></div>
        </div>
      </div>
      <div class="loginForm">
        <template v-if="currentIndex==1">
          <div class="loginForm_item flex_aling_center">
            <div class="loginLable flex_aling_center">
              <img src="@/assets/image/login/shouji.png" alt="">
              <span>86+</span>
            </div>
            <input class="loginInput" type="numer" v-model="form.phone" placeholder="请输入手机号">
            <div class="loginTail">
            </div>
          </div>
          <div class="loginForm_item flex_aling_center">
            <div class="loginLable flex_aling_center">
              <img class="anquan" src="@/assets/image/login/anquan.png" alt="">
            </div>
            <input class="loginInput" type="numer" v-model="form.code" placeholder="请输入验证码">

            <div class="loginTail" @click="getCode">
              {{codeTip}}
              <template v-if="!canGetCode">
                S
              </template>
            </div>
          </div>
        </template>

        <template v-if="currentIndex==2">
          <div class="loginForm_item flex_aling_center">
            <div class="loginLable flex_aling_center">
              <i class="el-icon-s-custom"></i>
            </div>
            <input class="loginInput" type="text" v-model="form.phone" placeholder="请输入账号">
            <div class="loginTail">
            </div>
          </div>
          <div class="loginForm_item flex_aling_center">
            <div class="loginLable flex_aling_center">
              <i class="el-icon-lock"></i>
            </div>
            <input @keyup.enter="confrim" class="loginInput" type="password" v-model="form.password" placeholder="请输入密码">
            <div class="loginTail">
              <img class="eye" src="@/assets/image/login/eye.png" alt="">
            </div>
          </div>
        </template>
        <div class="forget" @click="forget()">
          <span>
            忘记密码?
          </span>
        </div>
        <div class="recruit_btn" @click="confrim">确认</div>
        <div class="login_tip">
          登录/注册即代表您同意<span>《用户协议及隐私政策》</span>
        </div>
      </div>

    </el-dialog>

    <!-- 忘记密码 -->
    <el-dialog title="" :visible.sync="dialogVisibleForget" width="30%" :modal-append-to-body="false">
      <div slot="title" class="loginTitle">
      </div>
      <div class="select flex_aling_center">
        <div class="select_item select_active ">
          忘记密码
          <div class="select_line"></div>
        </div>

      </div>
      <div class="loginForm">
        <div class="loginForm_item flex_aling_center">
          <div class="loginLable flex_aling_center">
            <img src="@/assets/image/login/shouji.png" alt="">
            <span>86+</span>
          </div>
          <input class="loginInput" type="numer" placeholder="请输入手机号">
          <div class="loginTail">

          </div>
        </div>
        <div class="loginForm_item flex_aling_center">
          <div class="loginLable flex_aling_center">
            <img class="anquan" src="@/assets/image/login/anquan.png" alt="">
          </div>
          <input class="loginInput" type="numer" placeholder="请输入验证码">
          <div class="loginTail">
            {{codeTip}}
            <template v-if="!canGetCode">
              重新发送
            </template>
          </div>
        </div>

        <div class="loginForm_item flex_aling_center">
          <div class="loginLable flex_aling_center">
            <i class="el-icon-lock"></i>
          </div>
          <input class="loginInput" type="numer" placeholder="请输入密码">
          <div class="loginTail">

          </div>
        </div>
        <div class="loginForm_item flex_aling_center">
          <div class="loginLable flex_aling_center">
            <i class="el-icon-lock"></i>
          </div>
          <input class="loginInput" type="numer" placeholder="请确认密码">
          <div class="loginTail">
            <img class="eye" src="@/assets/image/login/eye.png" alt="">
          </div>
        </div>
        <div class="recruit_btn">确认</div>
      </div>

    </el-dialog>
  </div>
</template>
<script>
// import AMapLoader from "@amap/amap-jsapi-loader";
import addressData from "@/utils/addressData.js";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import SelectCity from "@/components/SelectCity/index.vue";
import {
  sendsms,
  getUser,
  userREG,
  change_login,
  bind_tencent,
} from "@/utils/network.js";
export default {
  components: { SelectCity },
  props: {
    bgColor: {
      type: String,
      default: "#fff",
    },
    fontColor: {
      type: String,
      default: "#333333",
    },
  },
  data() {
    return {
      path: "/",
      dialogVisible: false,
      dialogVisibleForget: false,
      dialogVisibleLogin: false,
      form: {
        code: "",
        phone: "",
      },
      currentIndex: 1,
      canGetCode: true,
      codeTip: "获取验证码",
      timer: null,
      loginStatus: 0,
      isMember: true,
    };
  },
  created() {
    this.path = this.$route.path;
    if (localStorage.getItem("userInfo")) {
      this.loginStatus = 1;
    }
  },
  computed: {
    ...mapState(["userInfo", "authType", "currentCity"]),
    isLogin() {
      //   localStorage.getItem("userInfo") ? true : false;
      return this.loginStatus == 1;
    },
  },
  mounted() {
    setTimeout(() => {
      let currentCity = localStorage.getItem("currentCity");

      if (!currentCity) {
        this.initAMap();
      }
    }, 500);
  },
  methods: {
    ...mapMutations(["clearState"]),
    ...mapActions(["getmember_info"]),
    initAMap() {
      let that = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            let item = {
              city_code: result.adcode,
              city_name: result.city,
            };

            that.$store.commit("SetCurrentCity", item);
            // 查询成功，result即为当前所在城市信息
          }
        });
      });
    },

    // 选择身份
    async handleCommand(e) {
      const res = await change_login({
        now_login: e,
      });
      if (res.code == 200) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        //  需要掉接口切换下身份
        this.getmember_info();
      }
    },
    codeWodn() {
      this.codeTip = 30;
      this.timer = setInterval(() => {
        if (this.codeTip <= 1) {
          this.canGetCode = true;
          this.codeTip = "获取验证码";
          clearInterval(this.timer);
          this.timer = null;
          return;
        }
        this.codeTip--;
      }, 1000);
    },
    async getCode() {
      if (!this.form.phone) {
        this.$message({
          message: "请输入",
          type: "error",
        });
        return;
      }
      if (this.canGetCode) {
        const res = await sendsms({
          phone: this.form.phone,
        });
        if (res.code == 200) {
          this.form.vifity_code = res.data.vifity_code;
          this.$message({
            message: "发送成功",
            type: "success",
          });
          this.canGetCode = false;
          this.codeWodn();
        } else {
          this.$message({
            message: "服务繁忙",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "倒计时结束后再发送",
          type: "error",
        });
      }
    },
    toggle(index) {
      if (index == 1) {
        this.form.password = "";
      }
      this.currentIndex = index;
    },
    // 登录
    async confrim() {
      let data = {
        ...this.form,
      };
      const res = await userREG(data);

      if (res.code == 200) {
        localStorage.setItem("member_id", JSON.stringify(res.data.member_id));
        this.$message({
          message: res.msg,
          type: "success",
        });
        // 切换身份
        const authRes = await change_login({
          member_id: res.data.member_id,
          now_login: this.isMember ? 1 : 2,
        });
        // 获取身份信息
        const resD = await this.getmember_info(res.data.member_id);
        // 个人身份
        if (resD.now_login == 1) {
          if (resD.member_status == 1) {
            this.$confirm("您还没有认证，是否先去认证, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/accountOptions");
              })
              .catch(() => {});
          }
        } else {
          // company_status  == 5 剧组邀请

          if (resD.company_status == 1 && resD.company_status != 5) {
            this.$confirm("您还没有认证，是否先去认证, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push("/companyInfo");
              })
              .catch(() => {});
          }
        }
        this.user = resD;
        // this.authType = resD.now_login;   区分公司和个人
        this.loginStatus = 1;
        // location.reload(); 刷新页面  或者事件
        this.$emit("confrimLogin");
        this.dialogVisibleLogin = false;
        // const resTim = await bind_tencent({
        //   member_id: res.data.member_id,
        // });
        // if (resTim.code == 200) {
        //   localStorage.setItem("userSig", resTim.data);
        //   // im 登录注册
        //   // TUILogin.login({
        //   // 	SDKAppID: 1600046669,
        //   // 	userID: member_id.toString(),
        //   // 	userSig: resTim.data,
        //   // 	useUploadPlugin: true, // If you need to send rich media messages, please set to true.
        //   // 	framework: `vue${vueVersion}` // framework used vue2 / vue3
        //   // }).catch((err) => {

        //   // }).then(() => {
        //   // 	// 设置头像
        //   // })
        // }
      } else {
      }
    },
    // 选择城市回调
    selectCityback(e) {
      this.dialogVisible = false;
      location.reload();
    },
    goPage(path) {
      if (this.$route.path == path) {
        return;
      }
      this.$routerGo(path);
    },
    out() {
      this.$confirm("您确定要退出吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          localStorage.clear();
          this.clearState();
          if (this.$route.path != "/") {
            this.$router.replace("/");
          }

          location.reload();
        })
        .catch(() => {});
    },
    jump(path) {
      this.$router.push(path);
      // this.$router.push({
      //   path: "/list",
      //   query: { type: index, bannerType },
      //   force: true,
      // });
      // window.location.reload();
    },
    toggleAuth() {},

    login() {
      this.dialogVisibleLogin = true;
    },
  },
};
</script>

<style  scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.16);

  // background: #ffffff;
  padding: 0 160px;
  .left {
    margin-right: 102px;
    padding-right: 36px;

    .companyName {
      font-weight: bold;
      font-size: 24px;
      letter-spacing: 10px;
    }
    .companyName_e {
      margin-top: 13px;
      font-size: 14px;
    }
    img {
      width: 88px;
      height: 43.8px;
      margin-right: 39px;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .tabs_item {
      text-align: center;
      // width: 100px;
      min-width: 120px;
      padding: 0 30px;
      font-size: 14px;
      font-weight: 650;
      color: #333333;
      .el-icon-location {
        font-size: 20px;
      }
      .city_toggle {
        margin-left: 5px;
        font-size: 10px;
      }
    }
    .tabs_city {
      width: 60%;
      padding: 0 15px !important;
    }
    .tabs_active {
      color: #f43a47 !important;
    }
    .loginBtn {
      margin-left: 50px;
      border-radius: 8px;
      height: 40px !important;
      border-style: solid;
      border-width: 1px;
      border-color: #ed3d55;
    }
  }
}
::v-deep .el-dialog {
  overflow: hidden;
  border-radius: 14px;
}
::v-deep .el-dialog__header {
  padding: 0;
}

// 登录框
.loginTitle {
  position: relative;
  height: 50px;
  background: linear-gradient(180deg, #fdf3f4, #fdf3f4 50%, #f2f4f7);
  .loginTitle_toggle {
    margin-left: 15px;

    color: #333333;

    font-weight: bold;
    font-size: 14px;
    color: #f43a47;
  }
}
.select {
  height: 30px;
  justify-content: center;
  width: 100%;
  margin-top: 0;
  font-size: 15px;
  .select_line {
    width: 30px !important;
  }
}
.recruit_btn {
  margin: 20px auto;
  width: 80%;
  height: 40px;
  background: linear-gradient(250deg, #ef3c24, #ef7a24);
  border-radius: 15px;
}
.loginForm {
  width: 100%;
  .forget {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    font-size: 12px;
    color: #f43a47;
  }
  .login_tip {
    display: flex;
    font-size: 12px;
    color: #666666;

    span {
      color: #f43a47;
    }
  }
  .loginForm_item {
    margin-top: 15px;
    font-size: 13px;
    color: #333333;
    width: 100%;
    height: 50px;
    border-radius: 10px;
    padding: 0 10px;
    border: 1px solid #7b7b7b;
    overflow: hidden;
    .loginInput {
      width: 100%;
      height: 50px;
      border: none;
      font-size: 13px;
    }
    img {
      width: 16px;
      height: 22px;
      margin-right: 10px;
    }
    .anquan {
      width: 20px;
    }
    .loginLable {
      width: 70px;
      i {
        font-size: 22px;
      }
    }
    .loginTail {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      font-size: 10px;
      color: #f43a47;
      .eye {
        width: 16px;
        height: 12px;
      }
    }

    input::placeholder {
      font-size: 12px; /* 设置占位符文字大小为18像素 */
      color: #909090; /* 设置占位符文字颜色 */
    }
    input:focus {
      outline: 0;
      border: none;
    }
  }
}
</style>