<template>
  <div class="FilterMenu">
    <div class="FilterMenu_item flex_aling_center">
      <div class="FilterMenu_item_label">
        角色属性:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':oneIndex==0}" @click="toggle(0,'')">不限</div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':oneIndex==1}" @click="toggle(1,'主演')">主演</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==2}" @click="toggle(2,'主要配角')">主要配角</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==3}" @click="toggle(3,'特约')">特约</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==4}" @click="toggle(4,'小特')">小特</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==5}" @click="toggle(5,'前景')">前景</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==6}" @click="toggle(6,'特型')">特型</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==7}" @click="toggle(7,'群演')">群演</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==8}" @click="toggle(8,'儿童演员')">儿童演员</div>
    </div>
    <div class="FilterMenu_item flex_aling_center">
      <div class="FilterMenu_item_label">
        拍摄档期:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':towIndex==0}" @click="toggleTow(0,[])">不限</div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':towIndex==1}" @click="toggleTow(1,'急招')">急招</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':towIndex==2}" @click="toggleTow(2,'时间')">选择时间筛选</div>

    </div>
    <!-- 视觉年龄 -->
    <div class="FilterMenu_item flex_aling_center">
      <div class="FilterMenu_item_label">
        性别:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':threeIndex==0}" @click="toggleThree(0,0)">不限</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':threeIndex==1}" @click="toggleThree(1,'男')">男</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':threeIndex==2}" @click="toggleThree(2,'女')">女</div>
    </div>
    <div class="FilterMenu_item flex_aling_center">
      <div class="FilterMenu_item_label">
        视觉年龄:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':fourIndex==0}" @click="toggleFour(0,0,0)">不限</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fourIndex==1}" @click="toggleFour(1,99,99)">自定义选择</div>
      <!-- <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fourIndex==2}" @click="toggleFour(2,20,25)">20-25岁</div> -->

    </div>
    <!-- 剧目类型 -->
    <div class="FilterMenu_item flex_aling_center" v-if="authType==1">
      <div class="FilterMenu_item_label">
        剧目类型:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':fiveIndex==0}" @click="toggleFive(0,'')">不限</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fiveIndex==1}" @click="toggleFive(1,'电影')">电影</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fiveIndex==2}" @click="toggleFive(2,'电视剧')">电视剧</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fiveIndex==3}" @click="toggleFive(3,'横屏短剧')">横屏短剧</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fiveIndex==4}" @click="toggleFive(4,'竖频短剧')">竖频短剧</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fiveIndex==5}" @click="toggleFive(5,'微电影')">微电影</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fiveIndex==6}" @click="toggleFive(6,'广告')">广告</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fiveIndex==7}" @click="toggleFive(7,'短视频')">短视频</div>

    </div>
    <!-- 剧目风格 -->
    <div class="FilterMenu_item flex_aling_center" v-if="authType==1">
      <div class="FilterMenu_item_label">
        剧目风格:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':sixIndex==0}" @click="toggleSix(0,'')">不限</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':sixIndex==1}" @click="toggleSix(1,'现代戏')">现代戏</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':sixIndex==2}" @click="toggleSix(2,'古装戏')">古装戏</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':sixIndex==3}" @click="toggleSix(3,'年代戏')">年代戏</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':sixIndex==4}" @click="toggleSix(4,'民国戏')">民国戏</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':sixIndex==5}" @click="toggleSix(5,'架空玄幻戏')">架空玄幻戏</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':sixIndex==6}" @click="toggleSix(6,'其他')">其他</div>

    </div>

    <!-- 日历筛选 -->
    <el-dialog title="" :visible.sync="dialogVisible" width="35%" @close="dialogClose">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-date"></i>
          日期选择
        </div>
      </div>
      <div class="Calendar_setting  flex">
        <div class="Calendar_setting_item">
          <!-- :isSwitch="false" -->
          <Calendar :open="false" :textIsShow="false" :markDays="calArr" @onDayClick="onDayClick"></Calendar>
        </div>
        <!-- <div class="Calendar_setting_item">
          <Calendar :open="false" :isSwitch="false" :markDays="calArr" @onDayClick="onDayClick" :addNum="1" :textIsShow="false"></Calendar>
        </div> -->
      </div>
      <!-- <div class="setting_dangqi flex_aling_center">
        确认
      </div> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" @click="Calconfrim(1)">确认</el-button>
      </div>

    </el-dialog>
    <el-dialog title="" :visible.sync="dialogVisibleAge" width="35%" @close="dialogClose1">
      <div slot="title" class="loginTitle flex_aling_center">
        <div class="loginTitle_toggle " @click="isMember=!isMember">
          <i class="el-icon-date"></i>
          选择视觉年龄
        </div>
      </div>
      <div class="Calendar_setting  ">
        <div class="Calendar_setting_lable">
          开始年龄：{{value[0]}}
        </div>
        <div class="Calendar_setting_lable">
          结束年龄：{{value[1]}}
        </div>
        <el-slider style="width:100%" v-model="value" range show-stops :max="100">
        </el-slider>
      </div>
      <!-- <div class="setting_dangqi flex_aling_center">
        确认
      </div> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleAge = false">取 消</el-button>
        <el-button type="danger" @click="Calconfrim(2)">确认</el-button>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      oneIndex: 0,
      towIndex: 0,
      threeIndex: 0,
      fourIndex: 0,
      fiveIndex: 0,
      sixIndex: 0,
      selectObj: {},
      dialogVisible: false,
      dialogVisibleAge: false,
      value: [0, 100],
      calArr: [],
    };
  },
  computed: {
    ...mapState(["authType"]),
  },
  methods: {
    async onDayClick(e) {
      if (this.calArr.includes(e.date)) {
        this.calArr = this.calArr.filter((item) => e.date != item);
      } else {
        this.calArr.push(e.date);
        const arr = Array.from(new Set(this.calArr));
        this.calArr = arr;
      }

      // e.date
    },
    Calconfrim(index) {
      if (index == 1) {
        this.selectObj.need_time = this.calArr;
        this.dialogClose();
      } else {
        this.selectObj.need_age_begin = this.value[0];
        this.selectObj.need_age_end = this.value[1];
        this.dialogClose1();
      }

      this.select();
    },
    dialogClose() {
      this.calArr = [];
      this.dialogVisible = false;
    },
    dialogClose1() {
      this.value = [];
      this.dialogVisibleAge = false;
    },

    toggle(index, value) {
      this.oneIndex = index;
      this.selectObj.movie_personattr = value;
      this.select();
    },
    toggleTow(index, value) {
      this.towIndex = index;
      if (value == "急招") {
        this.selectObj.is_fast = 2;
      } else if (value == "时间") {
        this.selectObj.is_fast = 0;
        this.dialogVisible = true;
      } else {
        this.selectObj.is_fast = 0;
        this.selectObj.need_time = [];
      }
      this.select();
    },
    toggleThree(index, value) {
      this.threeIndex = index;
      this.selectObj.need_sex = value;
      this.select();
    },
    toggleFour(index, value, value1) {
      this.fourIndex = index;
      if (value == 99) {
        this.dialogVisibleAge = true;
        return;
      }

      this.selectObj.need_age_begin = value;
      this.selectObj.need_age_end = value1;
      this.select();
    },
    toggleFive(index, value) {
      this.fiveIndex = index;
      this.selectObj.type = value;
      this.select();
    },
    toggleSix(index, value) {
      this.sixIndex = index;
      this.selectObj.style_type = value;
      this.select();
    },

    select() {
      this.$emit("select", this.selectObj);
    },
  },
};
</script>

<style lang="scss" scoped>
.FilterMenu {
  padding: 32px 29px;
  width: 100%;
  // height: 333px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 20px;
  .FilterMenu_item {
    height: 50px;
    // margin-bottom: 20px;
    .FilterMenu_item_label {
      width: 100px;
      margin-right: 20px;
      font-weight: 600;
      font-size: 17px;
      color: #333333;
    }
    .FilterMenu_item_select {
      margin-right: 15px;
      font-size: 15px;
      color: #333333;
      padding: 5px 10px;
    }
    .FilterMenu_active {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 64px;
      // height: 30px;
      padding: 5px 10px;
      background: #f43a47;
      border-radius: 6px;
      color: #fff;
    }
  }
}
::v-deep .el-slider__bar {
  background-color: #f56c6c;
}

/* 滑块颜色 */

::v-deep.el-slider__button {
  border: 2px solid #f56c6c;
}
.Calendar_setting {
  padding: 0 25px;
  .Calendar_setting_lable {
    margin-bottom: 10px;
  }
}
</style>

